import ReactModal from "react-modal";
import Button from "./Button";
import { couponSchema } from "../validation/validation";
import { Formik } from "formik";
import { editCoupon } from "../services/api";
import { addCoupon } from "../services/api";
import { useEffect, useState } from "react";
import { getPlans } from "../services/api";
import Loader from "react-loader-spinner";
import toast from "react-hot-toast";

const customStyles = {
  overlay: {
    top: "0%",
    left: "0%",
    backgroundColor: "#1b1b1b80",
    width: "100%",
    height: "100%",
    zIndex: "99999",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "auto",
    width: "40%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Modal = (props) => {
  const validationSchema = couponSchema;
  const [isLoader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getPlans();
      // console.log(res.data.list);
      setData(res?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const initialValues = {
    subscriptionId: "",
    discount: 0,
  };

  const handleEditCoupon = async (values) => {
    // console.log(values);
    setLoader(true);
    const inputs = {
      couponId: values.id,
      couponStatus: values.couponStatus,
    };
    const res = await editCoupon(inputs);
    setTimeout(() => {
      setLoader(false);
      props.Toggle();
      props.onClose();
    }, 1000);
    // console.log(res);
    if (res.status === 200) {
      toast.success("Updated successfully");
    }
  };

  const postCoupon = async (values) => {
    // console.log(values);
    setLoader(true);
    const res = await addCoupon(values);
    setTimeout(() => {
      setLoader(false);
      props.Toggle();
      props.onClose();
    }, 1000);
    console.log(res);
    if (res.status === 200) {
      toast.success("Coupon added successfully");
    }
  };

  return (
    <div>
      <ReactModal
        isOpen={props.isOpen}
        style={customStyles}
        onRequestClose={props.onClose}
        shouldCloseOnOverlayClick={true}
      >
        {props.id !== "" ? (
          <div className="w-full pt-5 px-5 flex items-start min-h-full flex-col gap-5">
            <p className="font-Mulish text-md font-medium text-gray-400">
              Edit Coupon
            </p>
            <Formik
              initialValues={{ id: props.id, couponStatus: props.status }}
              onSubmit={(values) => handleEditCoupon(values)}
              enableReinitialize
            >
              {({
                values,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                errors,
              }) => (
                <form className="w-full" onSubmit={handleSubmit}>
                  <div className="flex flex-col justify-start w-full">
                    <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                      Coupon Status
                    </p>
                    <select
                      className={`w-full h-9 border border-gray-200 md:px-3 font-Mulish text-sm font-Medium text-gray-500 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded ${
                        touched.couponStatus && errors.couponStatus
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="couponStatus"
                      id="couponStatus"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.couponStatus}
                    >
                      <option value="ACTIVE" label="Active" />
                      <option value="INACTIVE" label="Inactive" />
                    </select>
                    <div className="w-full h-2 mb-2">
                      <p
                        className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                          touched.couponStatus && errors.couponStatus
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        {errors.couponStatus}
                      </p>
                    </div>
                  </div>
                  <div className="w-full bg-white mt-2 flex justify-end items-center">
                    <Button
                      text="Cancel"
                      variant="danger"
                      type="button"
                      click={props.onClose}
                    />
                    <Button
                      type="submit"
                      text="Submit"
                      variant="normal"
                      loader={isLoader}
                    ></Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="w-full pt-5 px-5 flex items-start min-h-full flex-col gap-5">
            <p className="font-Mulish text-md font-medium text-gray-400">
              Add Coupon
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => postCoupon(values)}
              enableReinitialize
            >
              {({
                values,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                errors,
              }) => (
                <form className="w-full" onSubmit={handleSubmit}>
                  <div className="flex flex-col justify-start w-full">
                    <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                      Plan Name
                    </p>
                    <select
                      className={`w-full h-9 border border-gray-200 md:px-3 font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded ${
                        touched.subscriptionId && errors.subscriptionId
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="subscriptionId"
                      id="subscriptionId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subscriptionId}
                    >
                      <option value="" label="Select Plan" />
                      {data.map((item) => {
                        return <option value={item.id} label={item.planName} />;
                      })}
                    </select>
                    <div className="w-full h-2 mb-2">
                      <p
                        className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                          touched.subscriptionId && errors.subscriptionId
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        {errors.subscriptionId}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-start w-full">
                    <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                      Discount
                    </p>
                    <input
                      type="number"
                      className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                        touched.discount && errors.discount
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="discount"
                      id="discount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.discount}
                    />
                    <div className="w-full h-2 mb-2">
                      <p
                        className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                          touched.discount && errors.discount
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        {errors.discount}
                      </p>
                    </div>
                  </div>
                  <div className="w-full bg-white mt-2 flex justify-end items-center">
                    <Button
                      text="Cancel"
                      variant="danger"
                      type="button"
                      click={props.onClose}
                    />
                    <Button
                      type="submit"
                      text="Submit"
                      variant="normal"
                      loader={isLoader}
                    ></Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </ReactModal>
    </div>
  );
};

export default Modal;
