import { React, useState, useEffect } from "react";
import List from "../../components/List";
import { useHistory, useParams } from "react-router-dom";
import {
  getSubscriberTransactionList,
  getSubscriberById,
} from "../../services/api";
import _ from "lodash";
import Loader from "react-loader-spinner";
import { FiArrowLeftCircle } from "react-icons/fi";
import getSymbolFromCurrency from "currency-symbol-map";
import millify from "millify";
import { Data } from "../../currencyData";

const Subscribers = () => {
  let { id } = useParams();
  const columns = [
    { id: "transactionDate", label: "Date", minWidth: 100 },
    { id: "discount", label: "Discount", minWidth: 100 },
    {
      id: "planName",
      label: "Plan Name",
      minWidth: 100,
    },
    {
      id: "transactionStatus",
      label: "Status",
      align: "center",
      minWidth: 100,
      maxWidth: 150,
    },
    { id: "amount", label: "Amount", minWidth: 100 },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [isLoader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const history = useHistory();
  const [subscriber, setSubscriber] = useState();

  const fetchData = async () => {
    const res = await getSubscriberTransactionList(id, page, rowsPerPage);
    console.log(res?.data?.list);
    const data = res?.data?.list;

    const modifiedData = data?.map((item) => {
      const status = _.lowerCase(item?.status);
      const planCategory = _.lowerCase(item?.planName);

      return {
        amount: `${getSymbolFromCurrency(item?.currency)}${millify(
          item.amount / Data[item?.currency]
        )}`,
        discount: `${item?.discount}%`,
        transactionDate: new Date(item?.createdAt * 1000).toLocaleDateString(),
        transactionStatus:
          status === "completed" ? (
            <div className="bg-green-100 rounded-full flex items-center justify-center text-green-900 text-xs md:w-full py-1 font-Mulish px-1">
              {_.startCase(status)}
            </div>
          ) : (
            <div className="bg-red-100 rounded-full flex items-center justify-center text-red-900 text-xs md:w-full py-1 font-Mulish px-1">
              {_.startCase(status)}
            </div>
          ),
        planName: (
          <p
            className="cursor-pointer text-blue"
            onClick={() => history.push(`/plans/${item?.subscriptionPlanId}`)}
          >
            {_.startCase(planCategory)}
          </p>
        ),
      };
    });
    // console.log(res.data.count);
    setRows(modifiedData);
    setTotalRow(res?.data?.count);
    setTimeout(() => {
      setLoader(false);
      setTableLoader(false);
    }, 1000);
  };

  const planData = async () => {
    const res = await getSubscriberById(id);
    // console.log(res.data);
    setSubscriber(res?.data);
  };

  useEffect(() => {
    fetchData();
    planData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPage(newPage);
    setTableLoader(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setTableLoader(true);
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full min-h-full bg-lightBg px-2 lg:px-5 py-2 "
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2 py-2"
      >
        <div className="w-full rounded-lg flex  items-center justify-center lg:w-5/6 lg:m-auto lg:my-6 border-2 flex-col lg:mb-1 border-lightGray bg-white">
          <div className="flex md:items-center justify-center  w-full px-4 lg:px-6 my-4">
            <FiArrowLeftCircle
              className="text-2xl text-gray-500 cursor-pointer"
              onClick={() => history.goBack()}
            />
            <p className=" text-gray-500   font-xl font-Mulish font-semibold md:text-left w-full px-4">
              Subscriber
            </p>
          </div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-2">
            <div className=" w-full px-2 md:px-4 md:pl-8 md:pr-4 py-4 justify-self-center">
              <div className="flex  py-4 border border-lightBg w-full rounded-lg flex-col px-3 bg-white">
                <div className="flex  items-center w-full pb-2  flex-col space-y-8">
                  <div className="flex items-start w-full justify-between space-x-2">
                    <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                      Organisation :
                    </p>
                    <p
                      className="font-Mulish text-sm font-medium  flex-2 cursor-pointer text-blue"
                      onClick={() =>
                        history.push(
                          `/organisations/${subscriber?.organisation?.id}`
                        )
                      }
                    >
                      {subscriber?.organisation?.name || "--"}
                    </p>
                  </div>
                  <div className="flex items-start w-full justify-between space-x-1">
                    <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                      Current Plan :
                    </p>
                    <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
                      {subscriber?.plan?.planName || "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-full px-2 md:px-4 md:pl-8 md:pr-4 py-4 justify-self-center">
              <div className="flex  py-4 border border-lightBg w-full rounded-lg flex-col pl-3 md:px-3 bg-white">
                <div
                  className="flex items-center w-full  flex-col
                space-y-8 pb-2"
                >
                  <div className="flex items-start w-full justify-between space-x-2">
                    <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                      Last Transaction :
                    </p>
                    <p className="font-Mulish text-sm font-medium text-gray-700 flex-1">
                      {new Date(
                        subscriber?.last_transaction[0] * 1000
                      ).toLocaleDateString() || "--"}
                    </p>
                  </div>
                  <div className="flex items-start w-full justify-between">
                    <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                      Subscription Expiry :
                    </p>
                    <p className="font-Mulish text-sm font-semibold text-red-500 flex-1">
                      {new Date(
                        subscriber?.endDate * 1000
                      ).toLocaleDateString() || "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full items-center  px-12 py-3">
            <p className=" text-gray-500   font-2xl font-Mulish font-semibold text-left w-full">
              Subscription History
            </p>
          </div>
          <List
            columns={columns}
            rows={rows}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalRow={totalRow}
            rowsPerPage={rowsPerPage}
            page={page}
            tableLoader={tableLoader}
          />
        </div>
      </div>
    </div>
  );
};

export default Subscribers;
