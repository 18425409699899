import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getOrganisationById } from "../../services/api";
import { IMAGE_URL } from "../../constants";
import { Tooltip } from "@mui/material";
import { FiArrowLeftCircle, FiEdit } from "react-icons/fi";
import Loader from "react-loader-spinner";

const Organisation = () => {
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState();
  const [isLoader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await getOrganisationById(id);
      console.log(res, "resp");
      const profileData = res?.data;
      // console.log(profileData);
      setData(profileData);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full px-5 py-2 bg-lightBg min-h-full"
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col"
      >
        <div className="flex w-full items-center justify-start px-4 lg:px-8 py-4">
          <FiArrowLeftCircle
            className="text-2xl text-gray-500 cursor-pointer"
            onClick={() => history.goBack()}
          />
        </div>
        <div className="flex w-full items-center justify-center  pt-10 flex-col space-y-3">
          <Avatar
            src={`${IMAGE_URL}/busybee/${data?.profileImage}`}
            sx={{ width: 100, height: 100 }}
          />
          <p className="font-Mulish text-lg font-bold text-gray-700">
            {data?.businessName || "--"}
          </p>
          {/* <p className="font-Mulish text-sm font-bold text-gray-500">
            {data?.address || "--"}
          </p> */}
          {data?.status === "ACTIVE" ? (
            <div className="bg-green-200 rounded-3xl px-3 py-1">
              <p className="font-Mulish text-sm font-medium text-green-700">
                {data?.status || "--"}
              </p>
            </div>
          ) : (
            <div className="bg-red-200 rounded-3xl px-3 py-1">
              <p className="font-Mulish text-sm font-medium text-red-700">
                {data?.status || "--"}
              </p>
            </div>
          )}
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2  py-2">
          <div className=" w-full px-4 md:pl-8 md:pr-4 py-4 justify-self-center">
            <div className="flex  pt-4 border border-lightBg w-full rounded-lg flex-col px-4 lg:px-8 bg-white">
              <div className="flex items-center justify-between w-full">
                <p className="font-Mulish text-sm font-semibold text-gray-400">
                  Profile Information
                </p>
                <div>
                  <Tooltip placement="top" title="Edit Profile">
                    <div>
                      <FiEdit
                        className="text-xl text-gray-400 cursor-pointer"
                        onClick={() =>
                          history.push(`/organisations/edit/${id}`)
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="flex  items-center w-full md:py-8 py-6  flex-col space-y-8">
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                    Full Name :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
                    {`${data?.firstName} ${data?.lastName}` || "--"}
                  </p>
                </div>
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                    Job Title :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 text-left flex-2">
                    {data?.jobTitle || "--"}
                  </p>
                </div>
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                    Email :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
                    {data?.email || "--"}
                  </p>
                </div>
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                    Contact :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 text-left flex-2">
                    {data?.contact || "--"}
                  </p>
                </div>
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                    Website :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 text-left flex-2">
                    {data?.website || "--"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full justify-self-center px-4 md:pl-4 md:pr-8 py-4">
            <div className="flex  pt-4  border border-lightBg w-full rounded-lg flex-col px-4 lg:px-8 bg-white">
              <div className="flex items-center justify-start w-full">
                <p className="font-Mulish text-sm font-semibold text-gray-400">
                  Organisation Stats
                </p>
              </div>
              <div className="flex  items-center w-full md:py-8 py-6  flex-col space-y-8">
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-2 xl:flex-1">
                    Total Salesperson :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 xl:flex-2">
                    {data?.spCount || 0}
                  </p>
                </div>
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-2 xl:flex-1">
                    Total Retailers :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 xl:flex-2">
                    {data?.vendorCount || 0}
                  </p>
                </div>
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-2 xl:flex-1">
                    Plan Name :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 xl:flex-2">
                    {/* {data?.subscriptionPlan.plan.planName || "--"} */}
                  </p>
                </div>
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-2 xl:flex-1">
                    Joined Date :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 xl:flex-2">
                    {new Date(data?.createdAt * 1000).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex items-start w-full justify-between">
                  <p className="font-Mulish text-sm font-semibold text-gray-400 flex-2 xl:flex-1">
                    Last Transaction :
                  </p>
                  <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 xl:flex-2">
                    {/* {data?.lastTransaction[0]
                      ? new Date(
                          data?.lastTransaction[0] * 1000
                        ).toLocaleDateString()
                      : "--"} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organisation;
