import React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import OrganisationList from "./pages/Organisation/OrganisationList";
import Organisation from "./pages/Organisation/Organistion";
import EditOrganisation from "./pages/Organisation/EditOrganisation";
import VendorList from "./pages/Vendors/VendorList";
import Vendor from "./pages/Vendors/Vendor";
import EditVendor from "./pages/Vendors/EditVendor";
import PlanList from "./pages/Plans/PlanList";
import AddPlan from "./pages/Plans/AddPlan";
import Plan from "./pages/Plans/Plan";
import NotFound from "./components/NotFound";
import Subscribers from "./pages/Subscribers/Subscribers";
import Subscriber from "./pages/Subscribers/Subscriber";
import CouponList from "./pages/Coupons/CouponList";
import Settings from "./pages/Settings";
import TransactionList from "./pages/Transactions/TransactionList";
import TransactionDetail from "./pages/Transactions/TransactionDetail";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/organisations/:id" component={Organisation} />
      <Route
        exact
        path="/organisations/edit/:id"
        component={EditOrganisation}
      />
      <Route exact path="/organisations" component={OrganisationList} />
      <Route exact path="/vendors/:id" component={Vendor} />
      <Route exact path="/vendors/edit/:id" component={EditVendor} />
      <Route exact path="/vendors" component={VendorList} />
      <Route exact path="/plans/plan/:id?" component={AddPlan} />
      <Route exact path="/plans/:id" component={Plan} />
      <Route exact path="/plans" component={PlanList} />
      <Route exact path="/subscribers" component={Subscribers} />
      <Route exact path="/subscribers/:id" component={Subscriber} />
      <Route exact path="/coupons" component={CouponList} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/transactions/:id" component={TransactionDetail} />
      <Route exact path="/transactions" component={TransactionList} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
