import Button from "../../components/Button";
import { planSchema } from "../../validation/validation";
import { Formik } from "formik";
import { addPlan, getPlanById, editPlan } from "../../services/api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import SuccessModal from "../../components/SuccessModal";
import { FiArrowLeftCircle } from "react-icons/fi";

const AddPlan = () => {
  const validationSchema = planSchema;
  let { id } = useParams();
  const [data, setData] = useState();
  const history = useHistory();
  const [isLoader, setLoader] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const fetchData = async () => {
    setLoader(true);
    const res = await getPlanById(id);
    // console.log(res.data);
    setData(res?.data);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  useEffect(() => {
    id && fetchData();
  }, []);

  const initialValues = {
    planName: data?.planName || "",
    discount: data?.discount || "",
    duration: Math.floor(data?.duration / (30 * 24 * 60 * 60)) || "",
    priority: data?.priority || "",
    currency: data?.currency,
    description: data?.description || "",
    status: data?.status,
  };

  const postPlan = async (values) => {
    setFormLoader(true);
    if (id) {
      const inputs = {
        id: id,
        planName: values.planName,
        discount: values.discount,
        duration: Math.round(values.duration * 30 * 24 * 60 * 60),
        priority: values.priority,
        description: values.description,
        status: values.status,
        currency: values.currency,
      };
      const res = await editPlan(inputs);
      if (res.status === 200) {
        setIsOpen(true);
      }
      setTimeout(() => {
        setLoader(false);
        history.goBack();
        setFormLoader(false);
      }, 2100);
    } else {
      const inputs = {
        planName: values.planName,
        discount: values.discount,
        duration: Math.round(values.duration * 30 * 24 * 60 * 60),
        priority: values.priority,
        description: values.description,
        status: values.status,
        currency: values.currency,
      };
      console.log("vaal", inputs);
      const res = await addPlan(inputs);
      if (res.status === 200) {
        setIsOpen(true);
      }
      setTimeout(() => {
        setLoader(false);
        history.goBack();
        setFormLoader(false);
      }, 2100);
    }
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full min-h-full bg-lightBg px-2 lg:px-5 py-2"
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full lg:w-full max-w-full bg-gray-50 rounded-md flex min-h-max flex-col p-3 lg:pt-6 lg:pb-6 lg:px-10 my-2 items-center justify-center"
      >
        <div className="flex items-start justify-center p-5 w-full lg:w-3/4 bg-white border-2 border-lightBg rounded-lg flex-col lg:my-3">
          <div className="flex md:items-center justify-center  w-full  px-0 my-2">
            <FiArrowLeftCircle
              className="text-2xl text-gray-500 cursor-pointer"
              onClick={() => history.goBack()}
            />
            <p className=" text-gray-400   font-xl font-Mulish font-semibold md:text-left w-full px-4">
              {id ? "Edit Plan" : "Add Plan"}
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              postPlan(values);
            }}
            enableReinitialize
          >
            {({
              handleSubmit,
              values,
              handleBlur,
              handleChange,
              touched,
              errors,
              setFieldValue,
            }) => (
              <div className="w-full px-2">
                <form onSubmit={handleSubmit}>
                  <div className="w-full bg-white mt-2">
                    <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                      Plan Name
                    </p>
                    <input
                      type="text"
                      className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                        touched.planName && errors.planName
                          ? "bg-red-50 border-red-400"
                          : "null"
                      }`}
                      name="planName"
                      id="planName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.planName}
                      placeholder="e.g., Annual"
                    />
                    <div className="w-full h-2">
                      <p
                        className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                          touched.planName && errors.planName
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        {errors.planName}
                      </p>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-full bg-white mt-2">
                      <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                        Discount
                      </p>
                      <input
                        type="number"
                        onWheelCapture={(e) => {
                          e.target.blur();
                          e.stopPropagation();

                          // Refocus immediately
                          setTimeout(() => {
                            e.target.focus();
                          }, 200);
                        }}
                        className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                          touched.discount && errors.discount
                            ? "bg-red-50 border-red-400"
                            : "null"
                        }`}
                        name="discount"
                        id="discount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.discount}
                        placeholder="e.g., 10%"
                      />
                      <div className="w-full h-2">
                        <p
                          className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                            touched.discount && errors.discount
                              ? "visible"
                              : "invisible"
                          }`}
                        >
                          {errors.discount}
                        </p>
                      </div>
                    </div>
                    <div className="w-full bg-white mt-2 relative">
                      <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                        Duration (in months)
                      </p>
                      <input
                        type="number"
                        onWheelCapture={(e) => {
                          e.target.blur();
                          e.stopPropagation();

                          // Refocus immediately
                          setTimeout(() => {
                            e.target.focus();
                          }, 200);
                        }}
                        className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                          touched.duration && errors.duration
                            ? "bg-red-50 border-red-400"
                            : "null"
                        }`}
                        name="duration"
                        id="duration"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duration}
                        placeholder="e.g., 1 to 12"
                      />
                      <div className="w-full h-2">
                        <p
                          className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                            touched.duration && errors.duration
                              ? "visible"
                              : "invisible"
                          }`}
                        >
                          {errors.duration}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full bg-white mt-2">
                    <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                      Status
                    </p>
                    <select
                      className={`w-full h-9 border border-gray-200 md:px-3 font-Mulish text-sm font-Medium text-gray-500 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded ${
                        touched.status && errors.status
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="status"
                      id="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option label="Select" value="" />
                      <option label="Active" value="ACTIVE" />
                      <option label="Inactive" value="INACTIVE" />
                    </select>
                    <div className="w-full h-2">
                      <p
                        className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                          touched.status && errors.status
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        {errors.status}
                      </p>
                    </div>
                  </div>
                  <div className="w-full bg-white mt-2">
                    <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                      Currency
                    </p>
                    <select
                      className={`w-full h-9 border border-gray-200 md:px-3 font-Mulish text-sm font-Medium text-gray-500 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded ${
                        touched.currency && errors.currency
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="currency"
                      id="currency"
                      value={values.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option label="Select" value="" />
                      <option label="USD" value="USD" />
                      <option label="INR" value="INR" />
                    </select>
                    <div className="w-full h-2">
                      <p
                        className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                          touched.currency && errors.currency
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        {errors.currency}
                      </p>
                    </div>
                  </div>
                  <div className="w-full bg-white mt-2">
                    <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                      Priority
                    </p>
                    <input
                      type="number"
                      onWheelCapture={(e) => {
                        e.target.blur();
                        e.stopPropagation();

                        // Refocus immediately
                        setTimeout(() => {
                          e.target.focus();
                        }, 200);
                      }}
                      className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                        touched.priority && errors.priority
                          ? "bg-red-50 border-red-400"
                          : "null"
                      }`}
                      name="priority"
                      id="priority"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.priority}
                      placeholder="e.g., 1 to 10"
                    />
                    <div className="w-full h-2">
                      <p
                        className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                          touched.priority && errors.priority
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        {errors.priority}
                      </p>
                    </div>
                  </div>
                  <div className="w-full bg-white mt-2">
                    <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                      Description
                    </p>
                    <textarea
                      className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm  resize-none text-gray-500 ${
                        touched.description && errors.description
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="description"
                      id="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      placeholder="e.g., This plan is for 12 months"
                    />
                    <div className="w-full h-2">
                      <p
                        className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                          touched.description && errors.description
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        {errors.description}
                      </p>
                    </div>
                  </div>
                  <div className="w-full bg-white mt-2 flex justify-end items-center">
                    <Button
                      text="Cancel"
                      variant="danger"
                      type="button"
                      click={() => history.goBack()}
                      loader={formLoader}
                    />
                    <Button
                      type="submit"
                      text="Submit"
                      variant="normal"
                      loader={formLoader}
                    ></Button>
                  </div>
                </form>
              </div>
            )}
          </Formik>
          {isOpen && (
            <SuccessModal
              isOpen={isOpen}
              text={
                id
                  ? "Plan details updated successfully "
                  : "Plan added successfully "
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddPlan;
