import { FiArrowLeftCircle } from "react-icons/fi";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getVendorById, getImageLink, editVendor } from "../../services/api";
import { IMAGE_URL } from "../../constants";
import { Avatar, Button as IconButton } from "@mui/material";
import { FiUpload } from "react-icons/fi";
import Loader from "react-loader-spinner";
import toast from "react-hot-toast";
import { vendorSchema } from "../../validation/validation";
import Button from "../../components/Button";
import Autocomplete from "react-google-autocomplete";
import SuccessModal from "../../components/SuccessModal";

const EditVendor = () => {
  let { id } = useParams();
  const history = useHistory();

  const [data, setData] = useState();
  const [avatar, setAvatar] = useState();
  const [formLoader, setFormLoader] = useState(false);
  const validationSchema = vendorSchema;
  const [isLoader, setLoader] = useState(true);
  const [imageLoader, setImageLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const fetchData = async () => {
    const res = await getVendorById(id);
    // console.log(res?.data);
    if (res?.status === 200) {
      setAvatar(`${IMAGE_URL}/busybee/${res?.data?.businessLogo}`);
    }
    setData(res?.data);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const initialValues = {
    name: data?.businessName || "",
    contactPerson: data?.contacts[0]?.name || "",
    contact: data?.contacts[0]?.contact || "",
    profileImage: data?.businessLogo || "",
    locationName: data?.locationName || "",
    location: data?.location?.coordinates || "",
    status: data?.status,
  };

  const getMiniLink = async (id, result) => {
    setImageLoader(true);
    try {
      const res = await getImageLink(id, result);
      // console.log(res);
      setTimeout(() => {
        setImageLoader(false);
      }, 1000);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditVendor = async (values) => {
    const inputs = {
      id: data?.vendorId,
      businessName: values.name,
      location: values.location,
      locationName: values.locationName,
      contacts: [{ contact: values.contact, name: values.contactPerson }],
      status: values.status,
      businessLogo: values.profileImage,
    };
    console.log("val", inputs);
    setFormLoader(true);
    try {
      const res = await editVendor(inputs);
      console.log(res);
      if (res.status === 200) {
        setIsOpen(true);
      }
      setTimeout(() => {
        setLoader(false);
        history.goBack();
        setFormLoader(false);
      }, 2100);
    } catch (e) {
      console.log(e);
    } finally {
      setFormLoader(false);
    }
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full  px-5 py-2 bg-lightBg min-h-full flex items-start justify-center"
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full lg:w-3/4 max-w-full bg-gray-50 rounded-md flex min-h-max flex-col p-3 lg:py-6  lg:px-10"
      >
        <div className="flex items-start justify-center p-5 w-full bg-white border-2 border-lightBg rounded-lg flex-col lg:my-3">
          <div className="flex md:items-center justify-center  w-full  px-0  my-2">
            <FiArrowLeftCircle
              className="text-2xl text-gray-500 cursor-pointer"
              onClick={() => history.goBack()}
            />
            <p className=" text-gray-400   font-xl font-Mulish font-semibold md:text-left w-full px-4">
              Edit Retailer
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleEditVendor(values)}
            enableReinitialize
          >
            {({
              handleSubmit,
              values,
              handleBlur,
              handleChange,
              touched,
              errors,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className="w-full">
                <div className="w-full p-5 flex flex-col space-y-4">
                  <div className="flex flex-col w-full  items-center justify-start space-y-1">
                    <div
                      className=" flex items-center border border-gray-100 justify-center 
                    w-24 h-24 rounded-full self-start"
                    >
                      {imageLoader ? (
                        <Loader
                          type="Oval"
                          color="#FFBD39"
                          height={25}
                          width={30}
                          // radius={10}
                        />
                      ) : (
                        <Avatar sx={{ width: 100, height: 100 }} src={avatar} />
                      )}
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <IconButton
                        variant="text"
                        component="label"
                        size="small"
                        startIcon={<FiUpload className="text-gray-600 mt-3" />}
                      >
                        <p className="text-xs  font-Mulish font-light text-gray-600 mt-3 w-full text-left">
                          edit logo
                        </p>
                        <input
                          name="avatar"
                          accept=".png,.jpg,.jpeg"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={(e) => {
                            const fileReader = new FileReader();
                            // console.log(fileReader);
                            fileReader.onload = async () => {
                              if (fileReader.readyState === 2) {
                                // console.log(imageSize);
                                if (e.target.files[0].size > 2e6) {
                                  // console.log("image error");
                                  toast.error(
                                    "image size must be less than 2MB"
                                  );
                                } else {
                                  const image = await getMiniLink(
                                    id,
                                    fileReader.result
                                  );
                                  // console.log(image);
                                  setFieldValue("profileImage", image);
                                  // console.log(values.profileImage);
                                  setAvatar(fileReader.result);
                                }
                              }
                            };
                            fileReader.readAsDataURL(e.target.files[0]);
                          }}
                        />
                      </IconButton>
                      <p className="text-xs  font-Mulish font-light text-gray-600 mt-1 w-full">
                        (Image size must be less than 2MB)
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col w-full  items-start justify-center space-y-1 mt-2">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Business Name
                    </label>
                    <input
                      type="text"
                      className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500
                      ${
                        touched.name && errors.name
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="name"
                      id="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {touched.name && errors.name ? (
                      <label className="font-light font-Mulish text-red-500 text-xs">
                        {errors.name}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-col w-full  items-start justify-center space-y-1 mt-2">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Contact Person
                    </label>
                    <input
                      type="text"
                      className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500
                      ${
                        touched.contactPerson && errors.contactPerson
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="contactPerson"
                      id="contactPerson"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contactPerson}
                    />
                    {touched.contactPerson && errors.contactPerson ? (
                      <label className="font-light font-Mulish text-red-500 text-xs">
                        {errors.contactPerson}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-col w-full  items-start justify-center space-y-1 mt-2">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500
                      ${
                        touched.contact && errors.contact
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="contact"
                      id="contact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact}
                    />
                    {touched.contact && errors.contact ? (
                      <label className="font-light font-Mulish text-red-500 text-xs">
                        {errors.contact}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-col w-full   items-start justify-center space-y-1">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Status
                    </label>
                    <select
                      className={`w-full h-9 border border-gray-200 md:px-3 font-Mulish text-sm font-medium text-gray-500 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded ${
                        touched.status && errors.status
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="status"
                      id="status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.status}
                    >
                      <option value="ACTIVE" label="Active" />
                      <option value="INACTIVE" label="Inactive" />
                    </select>
                    {touched.status && errors.status ? (
                      <label className="font-light font-Mulish text-red-500 ">
                        {errors.status}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-col w-full  items-start justify-center space-y-1 mt-2">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Address
                    </label>

                    <Autocomplete
                      className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500
                    `}
                      id="locationName"
                      name="locationName"
                      value={values.locationName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      apiKey={process.env.REACT_APP_GOOGLE}
                      options={{
                        types: [],
                      }}
                      onPlaceSelected={(place) => {
                        setFieldValue("locationName", place.formatted_address);
                        setFieldValue(
                          "location[0]",
                          place.geometry.location.lat()
                        );
                        setFieldValue(
                          "location[1]",
                          place.geometry.location.lng()
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="flex  w-full items-center justify-end">
                  <>
                    <Button
                      text="Cancel"
                      variant="danger"
                      type="button"
                      click={() => history.goBack()}
                      loader={formLoader}
                    />
                    <Button
                      type="submit"
                      text="Submit"
                      variant="normal"
                      loader={formLoader}
                    />
                  </>
                </div>
              </form>
            )}
          </Formik>
          {isOpen && (
            <SuccessModal
              isOpen={isOpen}
              text="Vendor profile successfully Updated"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditVendor;
