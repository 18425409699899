import Notfound from "../assets/404.svg";
const NotFound = () => {
  return (
    <div className="flex items-center justify-center w-1/2 m-auto">
      <img src={Notfound} alt="not found" className="object-contain h-72" />
    </div>
  );
};

export default NotFound;
