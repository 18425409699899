import React, { useState } from "react";
import Logo from "../assets/Logo Black.svg";
import {
  FiGrid,
  FiLayers,
  FiList,
  FiDollarSign,
  FiSettings,
  FiMenu,
  FiSliders,
  FiLogOut,
  FiBox,
} from "react-icons/fi";
import { IoGiftOutline } from "react-icons/io5";
import { authStore } from "../store";
import { useHistory, useLocation } from "react-router-dom";

function Sidebar() {
  const location = useLocation();
  const { pathname } = location;

  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);

  const menuItems = [
    {
      name: "Dashboard",
      link: "/",
      icon: <FiGrid />,
    },
    {
      name: "Organisations",
      link: "/organisations",
      icon: <FiList />,
    },
    {
      name: "Retailers",
      link: "/vendors",
      icon: <FiBox />,
    },
    {
      name: "Subscribers",
      link: "/subscribers",
      icon: <FiLayers />,
    },
    {
      name: "Plans",
      link: "/plans",
      icon: <FiSliders />,
    },
    {
      name: "Revenue",
      link: "/transactions",
      icon: <FiDollarSign />,
    },
    {
      name: "Coupons",
      link: "/coupons",
      icon: <IoGiftOutline />,
    },
    {
      name: "Settings",
      link: "/settings",
      icon: <FiSettings />,
    },
  ];

  const handleToggle = () => {
    setIsMobile((o) => !o);
  };

  const logOut = () => {
    // console.log("hi");
    authStore.update((s) => {
      s.token = null;
    });
    history.push("/");
  };

  return (
    <>
      {isMobile ? (
        <div
          className="min-h-screen  fixed left-0 w-full z-10 inset-y-0 bg-black bg-opacity-40"
          onClick={handleToggle}
        ></div>
      ) : (
        ""
      )}
      <div className="w-full flex items-center space-x-4 px-4 max-h-12 lg:hidden fixed top-0 left-0 bg-white z-10">
        <FiMenu className="text-label text-2xl" onClick={handleToggle} />
        <div className="flex items-center px-4 py-8 lg:py-1 w-full">
          <img src={Logo} alt="" className="object-cover h-36" />
        </div>
      </div>

      <div
        style={{
          maxWidth: "210px",
          minWidth: "210px",
        }}
        className={`min-h-full w-52 bg-white md:shadow-md  flex fixed inset-y-0 left-0 transform ${
          !isMobile ? "-translate-x-full" : ""
        } lg:translate-x-0 transition 500 ease-linear z-20 overflow-auto`}
      >
        <div className="flex flex-col px-4  transform transition duration-500 ease-linear">
          <div className="flex items-center justify-center h-16 w-full">
            <img src={Logo} alt="" className=" object-cover" />
          </div>
          <div className="flex flex-col  px-2 py-2 mt-2">
            <ul className=" flex flex-col">
              {menuItems.map((item, index) => {
                return (
                  <li
                    className="flex  space-x-4 group cursor-pointer font-Mulish items-center py-4"
                    key={index}
                    onClick={() => {
                      history.push(item.link);
                      if (isMobile) {
                        handleToggle();
                      }
                      // console.log(pathname.split("/"));
                      // console.log(item.link.split("/"));
                    }}
                  >
                    <div className="flex items-center justify-center ">
                      <div
                        className={` group-hover:animate-bounce group-hover:text-primary 
                        text-xl ${
                          pathname.includes(item.link.toString())
                            ? pathname.split("/")[1] === item.link.split("/")[1]
                              ? "text-primary"
                              : "text-label"
                            : "text-label "
                        }`}
                      >
                        {item.icon}
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <p
                        className={`font-Mulish group-hover:text-primary font-normal ${
                          pathname.includes(item.link.toString())
                            ? pathname.split("/")[1] === item.link.split("/")[1]
                              ? "text-primary"
                              : "text-label"
                            : "text-label "
                        }`}
                      >
                        {item.name}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            className="flex space-x-4 items-center justify-center absolute bottom-2 left-0 px-6 py-3 cursor-pointer"
            onClick={logOut}
          >
            <FiLogOut className="text-xl text-red-600" />
            <p className="text-red-600 font-Mulish font-medium">Logout</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
