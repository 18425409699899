import React from "react";
import NoData from "../assets/noData.svg";
import {
  ResponsiveContainer,
  Area,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";

function Charts({ data, chartColor }) {
  return data?.length === 0 ? (
    <div className="h-60 flex items-center justify-center flex-col gap-1">
      <img src={NoData} alt="no data" />
      <p className="font-mulish font-normal text-sm text-gray-600">
        Nothing to show
      </p>
    </div>
  ) : (
    <ResponsiveContainer width="100%" height="100%" className=" h-60">
      <AreaChart
        width={730}
        height={250}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            {chartColor && (
              <stop offset="25%" stopColor={chartColor} stopOpacity={0.9} />
            )}
            {chartColor && (
              <stop offset="75%" stopColor={chartColor} stopOpacity={0.5} />
            )}
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          axisLine={false}
          padding={{ left: 30 }}
          style={{ fontSize: "11px" }}
        />
        <YAxis axisLine={false} style={{ fontSize: "11px" }} />
        <CartesianGrid strokeDasharray="0" vertical={false} />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="Total"
          stroke={chartColor}
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default Charts;
