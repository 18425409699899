import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getPlanById } from "../../services/api";
import { Tooltip } from "@mui/material";
import { FiEdit, FiArrowLeftCircle } from "react-icons/fi";
import Loader from "react-loader-spinner";
import _ from "lodash";

const Plan = () => {
  let { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const [isLoader, setLoader] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await getPlanById(id);
    // console.log(res.data);
    const plan = res?.data;

    const modifiedData = {
      planName: plan?.planName,
      priority: plan?.priority,
      discount: plan?.discount,
      pricePerSp: plan?.pricePerSp,
      currency: plan?.currency,
      duration: Math.floor(plan?.duration / (30 * 24 * 60 * 60)),
      status: _.lowerCase(plan?.status),
      description: plan?.description,
    };
    console.log("modified", modifiedData);
    setData(modifiedData);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full bg-lightBg px-5 py-2"
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2"
      >
        <div className="w-full md:w-3/4 bg-white p-4 md:p-10 border border-lightBg rounded-md capitalize my-8">
          <div className="flex items-center justify-between w-full">
            <div className="flex md:items-center justify-center  w-full  md:px-0  my-2">
              <FiArrowLeftCircle
                className="text-2xl text-gray-500 cursor-pointer"
                onClick={() => history.goBack()}
              />
              <p className=" text-gray-500   font-xl font-Mulish font-semibold md:text-left w-full px-4">
                Plan Details
              </p>
            </div>
            <div>
              <Tooltip placement="top" title="Edit Plan">
                <div>
                  <FiEdit
                    className="text-xl text-gray-400 cursor-pointer"
                    onClick={() => history.push(`/plans/plan/${id}`)}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="flex items-start justify-between mt-6 mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Plan Name :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
              {data?.planName}
            </p>
          </div>
          <div className="flex items-start justify-between  mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Priority :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
              {data?.priority}
            </p>
          </div>
          <div className="flex items-start justify-between  mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              discount:
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
              {data?.discount + "%"}
            </p>
          </div>
          <div className="flex items-start justify-between mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Price Per Salesperson:
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
              {data?.pricePerSp}
            </p>
          </div>
          <div className="flex items-start justify-between  mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Currency :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
              {data?.currency}
            </p>
          </div>
          <div className="flex items-start justify-between mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Duration :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
              {data?.duration + " Month"}
            </p>
          </div>
          <div className="flex items-start justify-between mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Status :
            </p>
            <div className="flex-2">
              {data?.status === "active" ? (
                <p className="font-Mulish text-sm font-medium text-green-700 bg-green-100 w-20 text-center py-1 rounded-full">
                  {_.startCase(data?.status)}
                </p>
              ) : (
                <p className="font-Mulish text-sm font-medium text-red-700 flex-2 bg-red-200 text-center w-28 py-1 rounded-full">
                  {data?.status}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-start justify-between mb-4  pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Description :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
              {data?.description || "--"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
