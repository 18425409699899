import { React, useState, useEffect } from "react";
import { getSubscribers, getPlans } from "../../services/api";
import List from "../../components/List";
import { useHistory } from "react-router";
import _ from "lodash";
import Loader from "react-loader-spinner";
import { FiArrowLeftCircle } from "react-icons/fi";

const Subscribers = () => {
  const columns = [
    { id: "name", label: "Subscriber", minWidth: 250 },
    { id: "planName", label: "Current Plan", minWidth: 200 },
    {
      id: "lastTransaction",
      label: "Last Transaction",
      minWidth: 200,
    },
    {
      id: "expiry",
      label: "Expiry",
      minWidth: 150,
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [sortField, setSortField] = useState("");
  const [plans, setPlans] = useState([]);
  const [planName, setPlanName] = useState("");
  const [isLoader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);

  const plansList = async () => {
    const res = await getPlans();
    // console.log(res.data.list);
    setPlans(res?.data?.list);
  };

  const fetchData = async () => {
    try {
      const res = await getSubscribers(
        page,
        rowsPerPage,
        query,
        sortField,
        planName
      );
      const data = res?.data?.result;
      console.log(data);
      const modifiedData = data?.map((item) => {
        const planCategory = _.lowerCase(item?.planType);

        return {
          id: item?.organisationId,
          name: (
            <p
              className=" truncate w-40 font-Mulish text-blue cursor-pointer"
              onClick={() =>
                history.push(`/organisations/${item?.organisationId}`)
              }
            >
              {item?.businessName}
            </p>
          ),
          planName: (
            <p
              className="text-blue cursor-pointer"
              onClick={() => history.push(`/plans/${item?.plan?.id}`)}
            >
              {_.startCase(planCategory)}
            </p>
          ),
          lastTransaction: <p>{item?.amount ?? "Trial"}</p>,
          expiry: (
            <p className="text-red-500 font-semibold">
              {new Date(item?.endDate * 1000).toLocaleDateString()}
            </p>
          ),
        };
      });
      // console.log(modifiedData);
      setRows(modifiedData);
      setTotalRow(res?.data?.count);
      setTimeout(() => {
        setLoader(false);
        setTableLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
      setTableLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    // plansList();
  }, [page, rowsPerPage, query, sortField, planName]);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPage(newPage);
    setTableLoader(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setTableLoader(true);
  };

  const handleView = (row) => {
    // console.log(row.id);
    history.push(`/subscribers/${row?.id}`);
  };

  const searching = _.debounce((e) => {
    // console.log(e.target.value);
    setQuery(e.target.value);
    setTableLoader(true);
  }, 250);

  const sorting = (e) => {
    // console.log(e.target.value);
    setSortField(e.target.value);
    setTableLoader(true);
  };

  const filtering = (e) => {
    // console.log(e.target.value);
    setPlanName(e.target.value);
    setTableLoader(true);
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full min-h-full bg-lightBg px-2 lg:px-5 py-2 "
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2 py-2"
      >
        <div className="w-full rounded-lg flex  items-center justify-center lg:w-5/6 lg:m-auto lg:my-6 border-2 flex-col lg:mb-1 border-lightGray bg-white">
          <div className="flex items-start md:justify-between md:px-6 py-2 w-full flex-col justify-center  space-y-2 md:flex-row">
            <div className="flex md:items-center justify-center  w-full  md:px-0 px-6 my-2">
              <FiArrowLeftCircle
                className="text-2xl text-gray-500 cursor-pointer"
                onClick={() => history.goBack()}
              />
              <p className=" text-gray-500   font-xl font-Mulish font-semibold md:text-left w-full px-4">
                Subscribers
              </p>
            </div>
            <div className="flex flex-col md:flex-row max-w-sm md:max-w-none md:space-x-2  w-full px-2 space-y-2 md:space-y-0">
              <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500   text-xs font-Mulish font-medium  w-full">
                  Plan Name
                </p>
                <select
                  className=" w-full md:w-40 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded px-2"
                  onChange={filtering}
                >
                  <option value="" label="All" />
                  {plans?.map((item) => {
                    return (
                      <option
                        value={item.planName}
                        label={_.upperFirst(item.planName)}
                        key={item.id}
                      />
                    );
                  })}
                </select>
              </div>
              <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500   text-xs font-Mulish font-medium  w-full">
                  Sort By
                </p>
                <select
                  className=" w-full md:w-40 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded px-2"
                  onChange={sorting}
                >
                  <option value="" label="All" />
                  <option value="createdAt" label="Latest" />
                  <option value="updatedAt" label="Last Updated" />
                  <option value="endDate" label="Expiry" />
                </select>
              </div>
              <div className="flex items-end justify-center md:px-0 px-6">
                <input
                  type="text"
                  className=" w-full md:w-48 h-8 border border-gray-200 px-3 font-Mulish text-sm focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded text-gray-600"
                  placeholder="Search"
                  onChange={searching}
                />
              </div>
            </div>
          </div>
          <List
            columns={columns}
            rows={rows}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalRow={totalRow}
            rowsPerPage={rowsPerPage}
            page={page}
            view={handleView}
            tableLoader={tableLoader}
          />
        </div>
      </div>
    </div>
  );
};

export default Subscribers;
