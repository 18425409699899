import React, { useState } from "react";
import Logo from "../assets/Logo Black.svg";
import LoginImg from "../assets/login.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { authStore } from "../store";
import { login } from "../services/api";
import Button from "../components/Button";

function Login() {
  const [isLoader, setLoader] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Field is required"),
    password: Yup.string().required("Field is required"),
  });

  const handleLogin = async (email, password) => {
    setLoader(true);
    // console.log(email, password);
    const res = await login(email, password);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
    // console.log(res);
    if (res.status === 200) {
      authStore.update((s) => {
        s.token = res.data?.token;
      });
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="w-full h-screen flex flex-col items-center justify-items-start md:justify-center xl:justify-center lg:justify-center px-3 py-3 bg-lightBg flex-2">
        <div
          style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
          className="w-full md:w-1/2 lg:w-1/3 bg-white p-10 rounded-xl"
        >
          <div className="flex items-center justify-center w-full h-28">
            <img src={Logo} className="p-6" alt="log" />
          </div>
          <div className="w-full mt-3">
            <p className="text-xl lg:text-2xl font-Mulishrat font-medium my-2 text-label text-center">
              Login to your account
            </p>
            <p className="font-Mulish text-center font-light text-subHeading text-xs md:text-sm lg:text-sm xl:text-sm py-3">
              Please login using your account credentials
            </p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => handleLogin(values.email, values.password)}
            >
              {({
                handleSubmit,
                values,
                handleBlur,
                handleChange,
                touched,
                errors,
              }) => (
                <form className="font-Mulish" onSubmit={handleSubmit}>
                  <div className="w-full space-y-4 flex flex-col mt-5">
                    <div className="w-full flex flex-col">
                      <input
                        type="text"
                        className={`w-full border appearance-none border-1 border-gray-200 my-1 rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm ${
                          touched.email && errors.email
                            ? "border-red-400 bg-red-50"
                            : "null"
                        }`}
                        name="email"
                        id="email"
                        placeholder="mail@abc.com"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        autoCapitalize="off"
                      />
                      {touched.email && errors.email ? (
                        <p className="font-light font-Mulish text-red-500 text-xs">
                          {errors.email}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <input
                      type="password"
                      className={`w-full border appearance-none border-1 border-gray-200 my-1 rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm ${
                        touched.password && errors.password
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="password"
                      id="password"
                      placeholder="**********"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {touched.password && errors.password ? (
                      <p className="font-light font-Mulish text-red-500 text-xs">
                        {errors.password}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="flex items-center w-full justify-center">
                      <Button
                        type="submit"
                        text="Sign In"
                        variant="normal"
                        loader={isLoader}
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
