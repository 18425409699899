import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  getOrganisationById,
  getPlanById,
  getTransactionById,
} from "../../services/api";
import _ from "lodash";
import { FiArrowLeftCircle } from "react-icons/fi";
import { Data } from "../../currencyData";
import getSymbolFromCurrency from "currency-symbol-map";
import millify from "millify";

const TransactionDetail = () => {
  let { id } = useParams();
  const [data, setData] = useState([]);
  const history = useHistory();

  const fetchData = async () => {
    const res = await getTransactionById(id);
    const transaction = res?.data;
    // console.log(res?.data);
    const response = await getOrganisationById(transaction?.organisationId);
    const organisation = response?.data;

    const planResponse = await getPlanById(transaction?.planId);
    const plan = planResponse?.data;

    const modifiedData = {
      orderId: transaction?.orderId,
      status: _.lowerCase(transaction?.status),
      date: new Date(transaction?.createdAt * 1000).toLocaleDateString(),
      organisation: organisation?.businessName,
      orgStatus: _.lowerCase(organisation?.status),
      spLimit: transaction?.spLimit,
      vendorLimit: transaction?.vendorLimit,
      planName: plan?.planName,
      amount: `${getSymbolFromCurrency(transaction?.currency)}${millify(
        transaction?.amount / Data[transaction?.currency]
      )}`,
    };
    setData(modifiedData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className="w-full min-h-full bg-lightBg px-5 py-2 "
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2"
      >
        <div className="w-full md:w-3/4 bg-white p-4 md:p-10 border border-lightBg rounded-md capitalize my-8">
          <div className="flex items-center justify-between w-full">
            <div className="flex md:items-center justify-center  w-full  md:px-0 my-2">
              <FiArrowLeftCircle
                className="text-2xl text-gray-500 cursor-pointer"
                onClick={() => history.goBack()}
              />
              <p className=" text-gray-500   font-xl font-Mulish font-semibold md:text-left w-full px-4">
                Transaction Detail
              </p>
            </div>
          </div>
          <div className="flex items-start justify-between mt-6 mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Order Id :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 md:flex-2">
              {data?.orderId}
            </p>
          </div>
          <div className="flex items-start justify-between  mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Transaction Status :
            </p>
            <div className="flex-1 md:flex-2">
              {data?.status === "completed" ? (
                <p className="font-Mulish text-sm text-center font-medium text-green-700 bg-green-100 w-28 py-1 rounded-full">
                  {_.startCase(data?.status)}
                </p>
              ) : (
                <p className="font-Mulish text-sm font-medium text-red-700 flex-2 bg-red-200 text-center w-28 py-1 rounded-full">
                  {data?.status}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-start justify-between  mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Date :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 md:flex-2">
              {data?.date}
            </p>
          </div>
          <div className="flex items-start justify-between mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Organisation :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 md:flex-2">
              {data?.organisation}
            </p>
          </div>
          <div className="flex items-start justify-between mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Organisation Status :
            </p>
            <div className="flex-1 md:flex-2">
              {data?.orgStatus === "active" ? (
                <p className="font-Mulish text-sm font-medium text-green-700 bg-green-100 w-20 text-center py-1 rounded-full">
                  {_.startCase(data?.orgStatus)}
                </p>
              ) : (
                <p className="font-Mulish text-sm font-medium text-red-700 flex-2 bg-red-200 text-center w-28 py-1 rounded-full">
                  {data?.orgStatus}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-start justify-between mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Salesperson Limit :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 md:flex-2">
              {data?.spLimit}
            </p>
          </div>
          {/* <div className="flex items-start justify-between  mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Salesperson Limit :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 md:flex-2">
              {data?.spLimit}
            </p>
          </div> */}
          <div className="flex items-start justify-between mb-4 border-b border-lightBg pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Plan Name :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 md:flex-2">
              {data?.planName}
            </p>
          </div>
          <div className="flex items-start justify-between  pb-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
              Amount :
            </p>
            <p className="font-Mulish text-sm font-medium text-gray-700 flex-1 md:flex-2">
              {data?.amount}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetail;
