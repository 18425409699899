import List from "../../components/List";
import { FiArrowLeftCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { getVendors } from "../../services/api";
import { useEffect } from "react";
import Loader from "react-loader-spinner";
import _ from "lodash";

const VendorList = () => {
  const history = useHistory();
  const columns = [
    {
      id: "name",
      label: "Name",
      minWidth: 150,
    },
    {
      id: "contact",
      label: "Contact",
      minWidth: 150,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 150,
      align: "center",
    },
    {
      id: "locationName",
      label: "Address",
      minWidth: 250,
    },
  ];

  const [rows, setRows] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState("");
  const [query, setQuery] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const [isLoader, setLoader] = useState(true);

  const fetchData = async () => {
    setOffset(page - 1);
    try {
      const res = await getVendors(page, rowsPerPage, query, sortField);
      console.log(res.data);
      const vendorList = res?.data?.result ?? [];
      const modifiedData = vendorList?.map(
        ({ businessName, locationName, status, ...rest }) => {
          const contacts = rest?.contacts;
          return {
            name: (
              <p className=" truncate w-40 font-Mulish font-bold text-gray-800">
                {businessName}
              </p>
            ),
            contact:
              contacts.length === 1 ? (
                <p className=" truncate w-36 font-Mulish">
                  {contacts[0].contact}
                </p>
              ) : (
                <p className=" truncate w-36 font-Mulish">--</p>
              ),
            status:
              status === "ACTIVE" ? (
                <div className="bg-green-100 rounded-full flex items-center justify-center text-green-900 text-xs w-full  py-1 px-2 font-Mulish">
                  {_.startCase(status?.toLowerCase())}
                </div>
              ) : (
                <div className="bg-red-100 rounded-full flex items-center justify-center text-red-900 text-xs w-full py-1 px-1 font-Mulish">
                  {_.startCase(status?.toLowerCase())}
                </div>
              ),
            locationName: (
              <p className=" truncate w-60 font-Mulish">{locationName}</p>
            ),
            ...rest,
          };
        }
      );
      setRows(modifiedData);
      setTotalRow(res?.data?.count);
      setTimeout(() => {
        setLoader(false);
        setTableLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
      setTableLoader(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    // console.log("newPage", newPage);
    setPage(newPage + 1);
    setTableLoader(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setTableLoader(true);
  };

  const sorting = (e) => {
    // console.log(e.target.value);
    setSortField(e.target.value);
    setTableLoader(true);
  };

  const searching = _.debounce((e) => {
    // console.log(e.target.value);
    setPage(1);
    setQuery(e.target.value);
    setTableLoader(true);
  }, 250);

  const handleView = (row) => {
    // console.log(row);
    history.push(`/vendors/${row?.id}`);
  };

  const handleEdit = (row) => {
    console.log(row);
    history.push(`/vendors/edit/${row?.id}`);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortField, query]);

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full min-h-full bg-lightBg px-2 lg:px-5 py-2 "
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2 py-2"
      >
        <div className="w-full rounded-lg flex  items-center  lg:w-5/6 lg:m-auto lg:my-6 border-2 flex-col  border-lightGray bg-white flex-1">
          <div className="flex items-start md:justify-between md:px-6 py-2 w-full flex-col justify-center  space-y-2 md:flex-row">
            <div className="flex md:items-center justify-center  w-full  md:px-0 px-6 my-2">
              <FiArrowLeftCircle
                className="text-2xl text-gray-500 cursor-pointer"
                onClick={() => history.goBack()}
              />
              <p className=" text-gray-500   font-xl font-Mulish font-semibold md:text-left w-full px-4">
                Retailers
              </p>
            </div>
            <div className="flex flex-col md:flex-row max-w-sm md:max-w-none md:space-x-2 px-2 space-y-2 md:space-y-0 w-full 2xl:w-1/2">
              <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500   text-xs font-Mulish font-medium  w-full">
                  Sort By
                </p>
                <select
                  className=" w-full md:w-48 h-8 border border-gray-200 px-2 md:px-3 font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded"
                  onChange={sorting}
                >
                  <option value="" label="All" />
                  <option value="createdAt" label="Latest" />
                  <option value="updatedAt" label="Last Updated" />
                </select>
              </div>
              <div className="flex items-end justify-end md:justify-center md:px-0 px-6">
                <input
                  type="text"
                  className="w-full md:w-52 h-8 border border-gray-200 px-3 font-Mulish font-xs focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 rounded"
                  placeholder="Search"
                  onChange={searching}
                />
              </div>
            </div>
          </div>

          <List
            columns={columns}
            rows={rows}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalRow={totalRow}
            rowsPerPage={rowsPerPage}
            page={offset}
            handleView={handleView}
            // handleEdit={handleEdit}
            tableLoader={tableLoader}
          />
        </div>
      </div>
    </div>
  );
};

export default VendorList;
