import { FiArrowLeftCircle, FiEdit } from "react-icons/fi";
import { Avatar, Tooltip } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { getVendorById } from "../../services/api";
import { useEffect, useState } from "react";
import { IMAGE_URL } from "../../constants";
import Loader from "react-loader-spinner";
import startCase from "lodash/startCase";

const Vendor = () => {
  let { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const [isLoader, setLoader] = useState(true);

  const fetchData = async () => {
    try {
      const res = await getVendorById(id);
      console.log(res.data);
      const profileData = res?.data;
      setData(profileData);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full px-5 py-2 bg-lightBg min-h-full"
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2 lg:px-0"
      >
        <div className="flex w-full items-center justify-start px-4 lg:px-8 py-4">
          <FiArrowLeftCircle
            className="text-2xl text-gray-500 cursor-pointer"
            onClick={() => history.goBack()}
          />
        </div>
        <div className="flex w-full items-center justify-center  pt-10 flex-col space-y-3">
          <Avatar
            src={`${IMAGE_URL}/busybee/${data?.businessLogo}`}
            sx={{ width: 100, height: 100 }}
          />
          <p className="font-Mulish text-lg font-bold text-gray-700">
            {data?.businessName || "--"}
          </p>
          <p className="font-Mulish text-sm font-bold text-gray-500">
            {data?.locationName || "--"}
          </p>
        </div>
        <div className="w-full lg:w-3/4 my-8 bg-white rounded-md p-8 lg:grid flex flex-col lg:grid-cols-2  border border-lightBg">
          <div className="flex items-center justify-between w-full col-span-2">
            <p className="font-Mulish text-sm font-semibold text-gray-400">
              Profile Information
            </p>
            <div>
              <Tooltip placement="top" title="Edit Profile">
                <div>
                  <FiEdit
                    className="text-xl text-gray-400 cursor-pointer"
                    onClick={() => history.push(`/vendors/edit/${id}`)}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          {/* First col */}
          <div className="flex  items-center w-full md:py-8 py-6  flex-col space-y-8">
            <div className="flex items-start w-full justify-between">
              <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                Full Name :
              </p>
              <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
                {data?.businessName || "--"}
              </p>
            </div>
            <div className="flex items-start w-full justify-between">
              <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                Mobile :
              </p>
              <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
                {data?.contacts[0]?.contact ?? "--"}
              </p>
            </div>
            <div className="flex items-start w-full justify-between">
              <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                Status :
              </p>
              <div className="flex-2">
                <p
                  className={`font-Mulish text-sm font-medium ${
                    data?.status === "ACTIVE"
                      ? "text-green-900 bg-green-200"
                      : "text-red-900 bg-red-200"
                  } w-max py-1 px-2 rounded-full`}
                >
                  {startCase(data?.status.toLowerCase()) ?? "--"}
                </p>
              </div>
            </div>
          </div>
          {/* second col */}
          <div className="flex  items-center w-full md:py-8 py-6  flex-col space-y-8 justify-self-end">
            <div className="flex items-start w-full justify-between">
              <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                Address :
              </p>
              <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
                {data?.locationName || "--"}
              </p>
            </div>
            <div className="flex items-start w-full justify-between">
              <p className="font-Mulish text-sm font-semibold text-gray-400 flex-1">
                Joined Date :
              </p>
              <p className="font-Mulish text-sm font-medium text-gray-700 flex-2">
                {new Date(data?.createdAt * 1000).toLocaleDateString() || "--"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor;
