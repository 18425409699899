import { Formik } from "formik";
import { settingsSchema } from "../validation/validation";
import Button from "../components/Button";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { editSettings } from "../services/api";
import GlobalSettings from "./GlobalSettings";
import SuccessModal from "../components/SuccessModal";

const initialValues = {
  email: "",
  oldPassword: "",
  newPassword: "",
};
const validationSchema = settingsSchema;

const Settings = () => {
  const history = useHistory();
  const [isProfileSettings, setIsProfileSettings] = useState("yes");
  const [formLoader, setFormLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const postSettings = async (email, oldPassword, newPassword) => {
    setFormLoader(true);
    try {
      const res = await editSettings(email, oldPassword, newPassword);
      console.log(res);

      if (res.status === 200) {
        setIsOpen(true);
      }
      setTimeout(() => {
        setFormLoader(false);
        history.push("/");
      }, 2100);
    } catch (e) {
      console.log(e);
    } finally {
      setFormLoader(false);
    }
  };

  return (
    <div className="w-full min-h-full bg-lightBg px-2 lg:px-5 py-2 ">
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2 py-2"
      >
        <div className="flex items-start justify-center p-5 w-full lg:w-3/4 bg-white border-2 border-lightBg rounded-lg flex-col lg:my-6">
          <p className="font-Mulish text-md font-semibold text-gray-400">
            Settings
          </p>
          <div className="w-full  h-12 mt-2 flex items-center justify-center">
            <div className="flex  items-center justify-center  bg-cyan-50 rounded-full border-2 border-cyan-100">
              <p
                onClick={() => setIsProfileSettings("yes")}
                className={`font-Mulish text-md px-4 py-1 cursor-pointer rounded-full transition ease-linear duration-500  ${
                  isProfileSettings === "yes"
                    ? " bg-blue text-white"
                    : "text-cyan-700"
                }`}
              >
                Profile Settings
              </p>
              <p
                onClick={() => setIsProfileSettings("no")}
                className={`font-Mulish text-md px-4 py-1 cursor-pointer rounded-full transition ease-linear duration-500 ${
                  isProfileSettings === "no"
                    ? "bg-blue text-white"
                    : "text-cyan-700"
                }`}
              >
                Global Settings
              </p>
            </div>
          </div>
          {isProfileSettings === "yes" ? (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) =>
                  postSettings(
                    values.email,
                    values.oldPassword,
                    values.newPassword
                  )
                }
              >
                {({
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  values,
                  touched,
                  errors,
                }) => (
                  <form className="w-full">
                    <div className="w-full bg-white mt-4">
                      <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                        Email
                      </p>
                      <input
                        type="email"
                        className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                          touched.email && errors.email
                            ? "bg-red-50 border-red-400"
                            : "null"
                        }`}
                        name="email"
                        id="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="Email"
                      />
                      <div className="w-full h-2">
                        <p
                          className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                            touched.email && errors.email
                              ? "visible"
                              : "invisible"
                          }`}
                        >
                          {errors.email}
                        </p>
                      </div>
                    </div>
                    <div className="w-full bg-white mt-2">
                      <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                        Old Password
                      </p>
                      <input
                        type="password"
                        className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                          touched.oldPassword && errors.oldPassword
                            ? "bg-red-50 border-red-400"
                            : "null"
                        }`}
                        name="oldPassword"
                        id="oldPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.oldPassword}
                        placeholder="Old Password"
                      />
                      <div className="w-full h-2">
                        <p
                          className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                            touched.oldPassword && errors.oldPassword
                              ? "visible"
                              : "invisible"
                          }`}
                        >
                          {errors.oldPassword}
                        </p>
                      </div>
                    </div>
                    <div className="w-full bg-white mt-2">
                      <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                        New Password
                      </p>
                      <input
                        type="password"
                        className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                          touched.newPassword && errors.newPassword
                            ? "bg-red-50 border-red-400"
                            : "null"
                        }`}
                        name="newPassword"
                        id="newPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        placeholder="New Password"
                      />
                      <div className="w-full h-2">
                        <p
                          className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                            touched.newPassword && errors.newPassword
                              ? "visible"
                              : "invisible"
                          }`}
                        >
                          {errors.newPassword}
                        </p>
                      </div>
                    </div>
                    <div className="w-full bg-white mt-2 flex justify-end items-center">
                      {formLoader ? (
                        <Loader
                          type="Oval"
                          color="#FFBD39"
                          height={30}
                          width={30}
                        />
                      ) : (
                        <>
                          <Button
                            type="button"
                            text="Submit"
                            variant="normal"
                            click={handleSubmit}
                          ></Button>
                        </>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
              {isOpen && (
                <SuccessModal isOpen={isOpen} text={"Updated Successfully"} />
              )}
            </>
          ) : (
            <GlobalSettings />
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
