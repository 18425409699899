import Button from "../../components/Button";
import { organistionSchema } from "../../validation/validation";
import { Formik } from "formik";
import Autocomplete from "react-google-autocomplete";
import { useHistory, useParams } from "react-router-dom";
import {
  getOrganisationById,
  editOrganisation,
  getImageLink,
} from "../../services/api";
import { useEffect, useState } from "react";
import { Avatar, Button as IconButton } from "@mui/material";
import { FiUpload } from "react-icons/fi";
import Loader from "react-loader-spinner";
import toast from "react-hot-toast";
import { IMAGE_URL } from "../../constants";
import { FiArrowLeftCircle } from "react-icons/fi";
import SuccessModal from "../../components/SuccessModal";
import countries from "../../countryList";

const EditOrganisation = () => {
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState();
  const [avatar, setAvatar] = useState();
  const [formLoader, setFormLoader] = useState(false);
  const validationSchema = organistionSchema;
  const [isLoader, setLoader] = useState(true);
  const [imageLoader, setImageLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const fetchData = async () => {
    const res = await getOrganisationById(id);
    if (res?.status === 200) {
      setAvatar(`${IMAGE_URL}/busybee/${res?.data?.profileImage}`);
    }
    setData(res?.data);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const initialValues = {
    bussinessName: data?.businessName || "",
    email: data?.email || "",
    password: "",
    locationName: data?.locationName || "",
    location: data?.location?.coordinates || "",
    website: data?.website || "",
    profileImage: data?.profileImage || "",
    status: data?.status,
    contact: data?.contact || "",
    currency: data?.currency,
  };

  const handleEditOrganistion = async (values) => {
    const inputs = {
      id: id,
      businessName: values.bussinessName,
      email: values.email,
      password: values.password,
      locationName: values.locationName,
      location: values.location,
      website: values.website,
      status: values.status,
      contact: values.contact,
      currency:
        typeof values.currency === "object"
          ? values.currency
          : JSON.parse(values.currency),
      profileImage: values.profileImage,
    };
    console.log("val", inputs);
    setFormLoader(true);
    const res = await editOrganisation(inputs);
    if (res.status === 200) {
      setIsOpen(true);
    }
    setTimeout(() => {
      setLoader(false);
      history.goBack();
      setFormLoader(false);
    }, 2100);
  };

  const getMiniLink = async (id, result) => {
    setImageLoader(true);
    try {
      const res = await getImageLink(id, result);
      console.log(res);
      setTimeout(() => {
        setImageLoader(false);
      }, 1000);
      return res?.data;
    } catch (e) {
      console.log(e);
    }
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full  px-5 py-2 bg-lightBg min-h-full flex items-center justify-center"
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full lg:w-3/4 max-w-full bg-gray-50 rounded-md flex min-h-max flex-col p-3 lg:py-6  lg:px-10"
      >
        <div className="flex items-start justify-center p-5 w-full bg-white border-2 border-lightBg rounded-lg flex-col lg:my-3">
          <div className="flex md:items-center justify-center  w-full  px-0  my-2">
            <FiArrowLeftCircle
              className="text-2xl text-gray-500 cursor-pointer"
              onClick={() => history.goBack()}
            />
            <p className=" text-gray-400   font-xl font-Mulish font-semibold md:text-left w-full px-4">
              Edit Organisation
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleEditOrganistion(values)}
            enableReinitialize
          >
            {({
              handleSubmit,
              values,
              handleBlur,
              handleChange,
              touched,
              errors,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className="w-full">
                <div className="w-full p-5 flex flex-col space-y-4">
                  <div className="flex flex-col w-full  items-center justify-start space-y-1">
                    <div
                      className=" flex items-center border border-gray-100 justify-center 
                    w-24 h-24 rounded-full self-start"
                    >
                      {imageLoader ? (
                        <Loader
                          type="Oval"
                          color="#FFBD39"
                          height={25}
                          width={30}
                          // radius={10}
                        />
                      ) : (
                        <Avatar sx={{ width: 100, height: 100 }} src={avatar} />
                      )}
                    </div>
                    <div className="flex flex-col items-start justify-start w-full">
                      <IconButton
                        variant="text"
                        component="label"
                        size="small"
                        startIcon={<FiUpload className="text-gray-600 mt-3" />}
                      >
                        <p className="text-xs  font-Mulish font-light text-gray-600 mt-3 w-full text-left">
                          edit logo
                        </p>
                        <input
                          name="avatar"
                          accept=".png,.jpg,.jpeg"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={(e) => {
                            // setImageSize(e.target.files[0].size);
                            // console.log(
                            //   e.target.files[0].size > 2e6
                            //     ? "error"
                            //     : "successfull"
                            // );
                            const fileReader = new FileReader();
                            // console.log(fileReader);
                            fileReader.onload = async () => {
                              if (fileReader.readyState === 2) {
                                // console.log(imageSize);
                                if (e.target.files[0].size > 2e6) {
                                  // console.log("image error");
                                  toast.error(
                                    "image size must be less than 2MB"
                                  );
                                } else {
                                  const image = await getMiniLink(
                                    id,
                                    fileReader.result
                                  );
                                  // console.log(image);
                                  setFieldValue("profileImage", image);
                                  // console.log(values.profileImage);
                                  setAvatar(fileReader?.result);
                                }
                              }
                            };
                            fileReader.readAsDataURL(e.target.files[0]);
                          }}
                        />
                      </IconButton>
                      <p className="text-xs  font-Mulish font-light text-gray-600 mt-1 w-full">
                        (Image size must be less than 2MB)
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col w-full  items-start justify-center space-y-1">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Bussiness Name
                    </label>
                    <input
                      type="text"
                      className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500
                      ${
                        touched.bussinessName && errors.bussinessName
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="bussinessName"
                      id="bussinessName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bussinessName}
                    />
                    {touched.bussinessName && errors.bussinessName ? (
                      <label className="font-light font-Mulish text-red-500 ">
                        {errors.bussinessName}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-col w-full  items-start justify-center space-y-1">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Email
                    </label>
                    <input
                      type="email"
                      className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500
                      ${
                        touched.email && errors.email
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="email"
                      id="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {touched.email && errors.email ? (
                      <label className="font-light font-Mulish text-red-500">
                        {errors.email}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-col w-full  items-start justify-center space-y-1">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Password
                    </label>
                    <input
                      type="password"
                      className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500
                      ${
                        touched.password && errors.password
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="password"
                      id="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile}
                    />
                    {touched.password && errors.password ? (
                      <label className="font-light font-Mulish text-red-500">
                        {errors.password}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-col w-full  items-start justify-center space-y-1">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Address
                    </label>
                    <Autocomplete
                      className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500  ${
                        touched.locationName && errors.locationName
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }
                    `}
                      id="locationName"
                      name="locationName"
                      value={values.locationName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      apiKey={process.env.REACT_APP_GOOGLE}
                      options={{
                        types: [],
                      }}
                      onPlaceSelected={(place) => {
                        // console.log("geometry", place.geometry.location.lat());
                        // console.log("geometry", place.geometry.location.lng());
                        setFieldValue("locationName", place.formatted_address);
                        setFieldValue(
                          "location[0]",
                          place.geometry.location.lat()
                        );
                        setFieldValue(
                          "location[1]",
                          place.geometry.location.lng()
                        );
                      }}
                    />
                    {touched.locationName && errors.locationName ? (
                      <label className="font-light font-Mulish text-red-500">
                        {errors.locationName}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex items-center justify-center md:space-x-6 flex-col md:flex-row space-y-4 md:space-y-0">
                    <div className="flex flex-col w-full   items-start justify-center space-y-1">
                      <label className="font-Mulish text-sm font-normal text-gray-600">
                        Website
                      </label>
                      <input
                        type="text"
                        className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500
                      ${
                        touched.website && errors.website
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                        name="website"
                        id="website"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.website}
                      />
                      {touched.website && errors.website ? (
                        <label className="font-light font-Mulish text-red-500 ">
                          {errors.website}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex flex-col w-full   items-start justify-center space-y-1">
                      <label className="font-Mulish text-sm font-normal text-gray-600">
                        Status
                      </label>
                      <select
                        className={`w-full h-9 border border-gray-200 md:px-3 font-Mulish text-sm font-Medium text-gray-500 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded ${
                          touched.status && errors.status
                            ? "border-red-400 bg-red-50"
                            : "null"
                        }`}
                        name="status"
                        id="status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.status}
                      >
                        <option value="ACTIVE" label="Active" />
                        <option value="INACTIVE" label="Inactive" />
                      </select>
                      {touched.status && errors.status ? (
                        <label className="font-light font-Mulish text-red-500 ">
                          {errors.status}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col w-full   items-start justify-center space-y-1">
                    <label className="font-Mulish text-sm font-normal text-gray-600">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      className={`w-full border appearance-none border-1 border-gray-200  rounded py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500
                      ${
                        touched.contact && errors.contact
                          ? "border-red-400 bg-red-50"
                          : "null"
                      }`}
                      name="contact"
                      id="contact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contact}
                    />
                    {touched.contact && errors.contact ? (
                      <label className="font-light font-Mulish text-red-500 ">
                        {errors.contact}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <div className="flex flex-col w-full   items-start justify-center space-y-1">
                        <label className="font-Mulish text-sm font-normal text-gray-600">
                          Currency
                        </label>
                        <select
                          className={`w-full h-9 border border-gray-200 md:px-3 font-Mulish text-sm font-Medium text-gray-500 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded ${
                            touched.currency && errors.currency
                              ? "border-red-400 bg-red-50"
                              : "null"
                          }`}
                          name="currency"
                          id="currency"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.currency}
                        >
                          <option
                            value={""}
                            label={
                              data?.currency
                                ? `${data?.currency?.country} ${data?.currency?.currency_code}`
                                : "Select"
                            }
                          />
                          {countries.map((item) => {
                            return (
                              <option
                                value={JSON.stringify({
                                  country: item.country,
                                  currency_code: item.currency_code,
                                })}
                                label={`${item.country} ${item.currency_code}`}
                              />
                            );
                          })}
                        </select>
                        {touched.currency && errors.currency ? (
                          <label className="font-light font-Mulish text-red-500 ">
                            {errors.currency}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex  w-full items-center justify-end">
                  <>
                    <Button
                      text="Cancel"
                      variant="danger"
                      type="button"
                      click={() => history.goBack()}
                      loader={formLoader}
                    />
                    <Button
                      type="submit"
                      text="Submit"
                      variant="normal"
                      loader={formLoader}
                    />
                  </>
                </div>
              </form>
            )}
          </Formik>
          {isOpen && (
            <SuccessModal
              isOpen={isOpen}
              text="Orgnisation profile successfully updated"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditOrganisation;
