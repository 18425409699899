import List from "../../components/List";
import { useState } from "react";
import { getTransactionlist, getOrganisations } from "../../services/api";
import { useEffect } from "react";
import _ from "lodash";
import Loader from "react-loader-spinner";
import getSymbolFromCurrency from "currency-symbol-map";
import millify from "millify";
import { Data } from "../../currencyData";
import { FiArrowLeftCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const TransactionList = () => {
  const columns = [
    { id: "orderId", label: "Order Id", minWidth: 140, maxWidth: 170 },
    { id: "createdAt", label: "Date", minWidth: 140, maxWidth: 170 },
    {
      id: "status",
      label: "Status",
      minWidth: 140,
      maxWidth: 170,
      align: "center",
    },
    { id: "name", label: "Organisation", minWidth: 140, maxWidth: 170 },
    { id: "planName", label: "Plan Name", minWidth: 140, maxWidth: 170 },
    { id: "amount", label: "Amount", minWidth: 70, maxWidth: 170 },
  ];

  const [rows, setRows] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orgData, setOrgData] = useState([]);
  const [sortField, setSortField] = useState("");
  const [isLoader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [status, setStatus] = useState("");
  const [query, setQuery] = useState("");
  const [value, setValue] = useState("");
  const history = useHistory();

  const fetchData = async () => {
    try {
      const res = await getTransactionlist({
        offset: page,
        limit: rowsPerPage,
        sortField: sortField,
        status: status,
        orgId: value.id,
      });
      console.log(res?.data);

      const transactions = res?.data ?? [];

      const modifiedData = transactions.map(
        ({
          orderId,
          status,
          amount,
          currency,
          createdAt,
          organization,
          plan,
          subscriptionPlanId,
          ...rest
        }) => {
          // console.log(organisation);
          const basicUnit = Data[currency];
          // console.log(basicUnit);
          const transactionStatus = _.lowerCase(status);
          const planCategory = _.lowerCase(plan?.planName);
          return {
            amount: `${getSymbolFromCurrency(currency)}${millify(
              amount / basicUnit
            )}`,

            name: (
              <p className="truncate w-28 font-Mulish cursor-pointer text-blue">
                {organization?.businessName}
              </p>
            ),
            orderId: <p className=" truncate w-28 font-Mulish">{orderId}</p>,
            status:
              transactionStatus === "completed" ? (
                <div className="bg-green-100 rounded-full flex items-center justify-center text-green-900 text-xs w-full  py-1 px-2 font-Mulish">
                  {_.startCase(transactionStatus)}
                </div>
              ) : (
                <div className="bg-red-100 rounded-full flex items-center justify-center text-red-900 text-xs w-full py-1 px-1 font-Mulish">
                  {_.startCase(transactionStatus)}
                </div>
              ),
            planName: (
              <p className=" cursor-pointer text-blue">
                {_.startCase(planCategory)}
              </p>
            ),
            createdAt: new Date(createdAt * 1000).toLocaleDateString(),
            ...rest,
          };
        }
      );
      setRows(modifiedData);
      setTotalRow(res?.data?.count);
      setTimeout(() => {
        setLoader(false);
        setTableLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
      setTableLoader(false);
    }
  };

  const getOrgData = async () => {
    const res = await getOrganisations({ query: query });
    // console.log(res?.data?.result);
    setOrgData(res?.data?.result ?? []);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTableLoader(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setTableLoader(true);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, sortField, status, value]);

  useEffect(() => {
    getOrgData();
  }, [query]);

  const sorting = (e) => {
    setSortField(e.target.value);
    setTableLoader(true);
  };

  const handleView = (row) => {
    history.push(`/transactions/${row?.id}`);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    setTableLoader(true);
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full min-h-full bg-lightBg flex items-center justify-center px-2 md:px-5 py-2"
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2 py-2"
      >
        <div className="w-full rounded-lg flex  items-center justify-center lg:w-5/6 lg:m-auto lg:my-6 border-2 flex-col lg:mb-1 border-lightGray bg-white">
          <div className="flex items-start md:justify-between md:px-6 py-2 w-full flex-col justify-center  space-y-2 md:flex-row">
            <div className="flex md:items-center justify-center  w-full  md:px-0 px-6 my-2">
              <FiArrowLeftCircle
                className="text-2xl text-gray-500 cursor-pointer"
                onClick={() => history.goBack()}
              />
              <p className=" text-gray-500   font-xl font-Mulish font-semibold md:text-left w-full px-4">
                Transactions
              </p>
            </div>
            <div className="flex flex-col md:flex-row max-w-sm md:max-w-none md:space-x-2  w-full px-2 space-y-2 md:space-y-0">
              <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500   text-xs font-Mulish font-medium  w-full">
                  Status
                </p>
                <select
                  className=" w-full md:w-32 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded px-2"
                  onChange={handleStatus}
                >
                  <option value="" label="All" />
                  <option value="COMPLETED" label="Completed" />
                  <option value="FAILED" label="Failed" />
                  <option value="INITIATED" label="Initiated" />
                  <option value="EXPIRED" label="Expired" />
                </select>
              </div>
              {/* <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500   text-xs font-Mulish font-medium  w-full">
                  Plan Name
                </p>
                <select
                  className=" w-full md:w-32 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded px-2"
                  onChange={filtering}
                >
                  <option value="" label="All" />
                  {plans?.map((item) => {
                    return (
                      <option
                        value={item.planName}
                        label={item.planName}
                        key={item.id}
                      />
                    );
                  })}
                </select>
              </div> */}
              <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500   text-xs font-Mulish font-medium  w-full">
                  Sort By
                </p>
                <select
                  className=" w-full md:w-32 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded px-2"
                  onChange={sorting}
                >
                  <option value="" label="All" />
                  <option value="createdAt" label="Latest" />
                  <option value="updatedAt" label="Last Updated" />
                </select>
              </div>
              <div className="flex items-end justify-center md:px-0 px-6 w-full">
                <Autocomplete
                  className="w-full md:w-56 border-gray-200"
                  // style={{ width: 200}}
                  freeSolo
                  size="small"
                  id="free-solo-2-demo"
                  // disableClearable
                  filterOptions={(x) => x}
                  options={orgData}
                  autoComplete
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.businessName
                  }
                  onChange={(event, newValue) => {
                    setValue(newValue ?? "");
                  }}
                  onInputChange={(event, newInputValue) => {
                    // console.log("new", newInputValue);
                    setQuery(newInputValue);
                  }}
                  value={value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search"
                      fullWidth
                      // classes={}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <List
            columns={columns}
            rows={rows}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalRow={totalRow}
            rowsPerPage={rowsPerPage}
            page={page}
            handleView={handleView}
            tableLoader={tableLoader}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionList;
