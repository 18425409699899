import { useEffect, useState } from "react";
import { getPlans } from "../../services/api";
import List from "../../components/List";
import Button from "../../components/Button";
import { useHistory } from "react-router";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { FiArrowLeftCircle } from "react-icons/fi";

const PlanList = () => {
  const columns = [
    {
      id: "planName",
      label: "Plan Name",
      minWidth: 200,
    },
    {
      id: "duration",
      label: "Duration",
      minWidth: 150,
    },
    {
      id: "discount",
      label: "Discount",
      minWidth: 150,
    },
    {
      id: "priority",
      label: "Priority",
      minWidth: 100,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 160,
      align: "center",
    },
  ];

  const [rows, setRows] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();
  const [isLoader, setLoader] = useState(true);
  const [query, setQuery] = useState("");
  const [tableLoader, setTableLoader] = useState(false);
  const [sortField, setSortField] = useState("");
  const [status, setStatus] = useState();

  const handleChangePage = (event, newPage) => {
    // console.log("newPage", newPage);
    setPage(newPage + 1);
    setTableLoader(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setTableLoader(true);
  };

  const handleView = (row) => {
    // console.log(id);
    history.push(`/plans/${row?.id}`);
  };

  const handleEdit = (row) => {
    // console.log(id);
    history.push(`/plans/plan/${row?.id}`);
  };

  const fetchData = async () => {
    try {
      const res = await getPlans(page, rowsPerPage, query, sortField, status);
      const plans = res?.data?.data;
      console.log(res?.data);
      const modifiedData = plans?.map(
        ({ status, duration, discount, ...rest }) => {
          const planStatus = _.lowerCase(status);
          return {
            status:
              planStatus === "active" ? (
                <div className="bg-green-100 rounded-full flex items-center justify-center text-green-900 text-xs md:w-full py-1 font-Mulish">
                  {_.startCase(planStatus)}
                </div>
              ) : (
                <div className="bg-red-100 rounded-full flex items-center justify-center text-red-900 text-xs md:w-full py-1 font-Mulish">
                  {_.startCase(planStatus)}
                </div>
              ),
            duration: Math.floor(duration / (30 * 24 * 60 * 60)) + " Month",

            discount: discount + "%",
            ...rest,
          };
        }
      );
      setRows(modifiedData);
      setTimeout(() => {
        setLoader(false);
        setTableLoader(false);
      }, 1000);
      setTotalRow(res?.data?.count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
      setTableLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, query, sortField, status]);

  const searching = _.debounce((e) => {
    // console.log(e.target.value);
    setQuery(e.target.value);
    setTableLoader(true);
  }, 250);

  const sorting = (e) => {
    setSortField(e.target.value);
    setTableLoader(true);
  };

  const filtering = (e) => {
    setStatus(e.target.value);
    setTableLoader(true);
  };

  return isLoader ? (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      <Loader type="Oval" color="#FFBD39" height={50} width={60} />
    </div>
  ) : (
    <div
      className="w-full min-h-full bg-lightBg flex items-center justify-center px-2 md:px-5 py-2"
      style={{ minHeight: "calc(100vh - 3.5rem)" }}
    >
      <div
        style={{ boxShadow: "4px 1px 10px #e9e9e9" }}
        className="w-full max-w-full bg-gray-50 rounded-md flex items-center justify-start min-h-full flex-col px-2 py-2"
      >
        <div className="w-full rounded-lg flex  items-center justify-center lg:w-5/6 lg:m-auto lg:my-6 border-2 flex-col lg:mb-1 border-lightGray bg-white">
          <div className="flex items-start md:justify-between md:px-6 py-2 w-full flex-col justify-center  space-y-2 md:flex-row">
            <div className="flex md:items-center justify-center  w-full  md:px-0 px-6 my-2">
              <FiArrowLeftCircle
                className="text-2xl text-gray-500 cursor-pointer"
                onClick={() => history.goBack()}
              />
              <p className=" text-gray-500   font-xl font-Mulish font-semibold md:text-left w-full px-4">
                Plans
              </p>
            </div>
            <div className="flex flex-col md:flex-row max-w-sm md:max-w-none md:space-x-2  w-full px-2 space-y-2 md:space-y-0 ">
              <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500  text-xs font-Mulish font-medium  w-full">
                  Sort By
                </p>
                <select
                  className=" w-full px-2  md:w-40 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded"
                  onChange={sorting}
                >
                  <option value="" label="All" />
                  <option value="createdAt" label="Latest" />
                  <option value="updatedAt" label="Last Updated" />
                </select>
              </div>
              <div className="flex md:items-start flex-col w-full space-y-2 md:px-0 px-6">
                <p className=" text-gray-500  text-xs font-Mulish font-medium  w-full">
                  Status
                </p>
                <select
                  className=" w-full  px-2 md:w-40 h-8 border border-gray-200  font-Mulish text-sm font-extralight text-gray-400 bg-white focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded"
                  onChange={filtering}
                >
                  <option value="" label="All" />
                  <option value="ACTIVE" label="Active" />
                  <option value="INACTIVE" label="Inactive" />
                </select>
              </div>
              <div className="flex items-end justify-center md:px-0 px-6">
                <input
                  type="text"
                  className=" w-full md:w-48 h-8 border border-gray-200 px-3 font-Mulish text-sm focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent rounded text-gray-600"
                  placeholder="Search"
                  onChange={searching}
                />
              </div>
              <div className="flex items-end justify-end md:justify-center md:px-0 px-6">
                <Button
                  category="rounded"
                  variant="primary"
                  text="Add Plan"
                  click={() => history.push("/plans/plan")}
                />
              </div>
            </div>
          </div>
          <List
            columns={columns}
            rows={rows}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalRow={totalRow}
            rowsPerPage={rowsPerPage}
            page={page}
            handleView={handleView}
            edit={handleEdit}
            tableLoader={tableLoader}
          />
        </div>
      </div>
    </div>
  );
};

export default PlanList;
