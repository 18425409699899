import { Formik } from "formik";
import React from "react";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import SuccessModal from "../components/SuccessModal";
import { getGlobalSettings, updateGlobalSettings } from "../services/api";
import { GlobalsettingsSchema } from "../validation/validation";

const GlobalSettings = () => {
  const [formLoader, setFormLoader] = React.useState();
  const [data, setData] = React.useState();
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();

  const getData = async () => {
    try {
      const res = await getGlobalSettings();
      console.log(res);
      setData(res?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const validationSchema = GlobalsettingsSchema;
  const initialValues = {
    pricePerSpINR: data?.spPriceINR || "",
    pricePerSpUSD: data?.spPriceUSD || "",
    trialDuration: Math.floor(data?.trailDuration / (30 * 24 * 60 * 60)) || "",
    trialSalesperson: data?.trailSalesPersons ?? "",
  };

  const postSettings = async (values) => {
    const inputs = {
      spPriceINR: values.pricePerSpINR,
      spPriceUSD: values.pricePerSpUSD,
      trailDuration: Math.floor(values.trialDuration * 30 * 24 * 60 * 60),
      trailSalespersons: values.trialSalesperson,
    };
    console.log(inputs);
    try {
      const res = await updateGlobalSettings(inputs);
      //   console.log(res);
      if (res.status === 200) {
        setIsOpen(true);
      }
      setTimeout(() => {
        setFormLoader(false);
        history.push("/");
      }, 2100);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => postSettings(values)}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          errors,
        }) => (
          <form className="w-full">
            <div className="w-full bg-white mt-4">
              <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                Price per Salesperson (INR)
              </p>
              <input
                type="number"
                onWheelCapture={(e) => {
                  e.target.blur();
                  e.stopPropagation();

                  // Refocus immediately
                  setTimeout(() => {
                    e.target.focus();
                  }, 200);
                }}
                className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                  touched.pricePerSpINR && errors.pricePerSpINR
                    ? "bg-red-50 border-red-400"
                    : "null"
                }`}
                name="pricePerSpINR"
                id="pricePerSpINR"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pricePerSpINR}
                placeholder="100 INR"
              />
              <div className="w-full h-2">
                <p
                  className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                    touched.pricePerSpINR && errors.pricePerSpINR
                      ? "visible"
                      : "invisible"
                  }`}
                >
                  {errors.pricePerSpINR}
                </p>
              </div>
            </div>
            <div className="w-full bg-white mt-2">
              <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                Price per Salesperson (USD)
              </p>
              <input
                type="number"
                onWheelCapture={(e) => {
                  e.target.blur();
                  e.stopPropagation();

                  // Refocus immediately
                  setTimeout(() => {
                    e.target.focus();
                  }, 200);
                }}
                className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                  touched.pricePerSpUSD && errors.pricePerSpUSD
                    ? "bg-red-50 border-red-400"
                    : "null"
                }`}
                name="pricePerSpUSD"
                id="pricePerSpUSD"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pricePerSpUSD}
                placeholder="100 USD"
              />
              <div className="w-full h-2">
                <p
                  className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                    touched.pricePerSpUSD && errors.pricePerSpUSD
                      ? "visible"
                      : "invisible"
                  }`}
                >
                  {errors.pricePerSpUSD}
                </p>
              </div>
            </div>
            <div className="w-full bg-white mt-2">
              <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                Trial Salesperson
              </p>
              <input
                type="number"
                onWheelCapture={(e) => {
                  e.target.blur();
                  e.stopPropagation();

                  // Refocus immediately
                  setTimeout(() => {
                    e.target.focus();
                  }, 200);
                }}
                className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                  touched.trialSalesperson && errors.trialSalesperson
                    ? "bg-red-50 border-red-400"
                    : "null"
                }`}
                name="trialSalesperson"
                id="trialSalesperson"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.trialSalesperson}
                placeholder="Number of salespersons for trial"
              />
              <div className="w-full h-2">
                <p
                  className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                    touched.trialSalesperson && errors.trialSalesperson
                      ? "visible"
                      : "invisible"
                  }`}
                >
                  {errors.trialSalesperson}
                </p>
              </div>
            </div>
            <div className="w-full bg-white mt-2">
              <p className="font-Mulish text-sm font-normal text-gray-600 py-2">
                Trial Duration
              </p>
              <input
                type="number"
                onWheelCapture={(e) => {
                  e.target.blur();
                  e.stopPropagation();

                  // Refocus immediately
                  setTimeout(() => {
                    e.target.focus();
                  }, 200);
                }}
                className={`w-full border appearance-none border-1 border-gray-200  rounded-md py-2 px-4 focus:outline-none focus:ring-1 focus:ring-blue focus:border-transparent text-sm text-gray-500 ${
                  touched.trialDuration && errors.trialDuration
                    ? "bg-red-50 border-red-400"
                    : "null"
                }`}
                name="trialDuration"
                id="trialDuration"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.trialDuration}
                placeholder="1 to 12 months"
              />
              <div className="w-full h-2">
                <p
                  className={`font-light font-Mulish text-red-500 text-xs pt-1 pb-3  ${
                    touched.trialDuration && errors.trialDuration
                      ? "visible"
                      : "invisible"
                  }`}
                >
                  {errors.trialDuration}
                </p>
              </div>
            </div>
            <div className="w-full bg-white mt-2 flex justify-end items-center">
              {formLoader ? (
                <Loader type="Oval" color="#FFBD39" height={30} width={30} />
              ) : (
                <>
                  <Button
                    type="button"
                    text="Submit"
                    variant="normal"
                    click={handleSubmit}
                  ></Button>
                </>
              )}
            </div>
          </form>
        )}
      </Formik>
      {isOpen && <SuccessModal isOpen={isOpen} text={"Updated Successfully"} />}
    </div>
  );
};

export default GlobalSettings;
