import React from "react";
import Loader from "react-loader-spinner";

function Button(props) {
  if (props.variant === "normal") {
    return (
      <button
        type={props.loader ? "button" : props.type}
        className={`bg-primary text-black100 font-Mulish text-sm focus:outline-none rounded px-8 py-2 font-normal cursor-pointer h-10 ${
          props.category || props.text === "Sign In" ? "rounded-md w-full" : ""
        }`}
        onClick={!props.loader && props.click}
      >
        {props.loader ? (
          <div className="flex items-center justify-center w-full">
            <Loader width={20} height={20} type="Oval" color="#fff" />
          </div>
        ) : (
          props.text
        )}
      </button>
    );
  }
  if (props.variant === "primary") {
    return (
      <button
        type={props.type}
        className={`bg-blue text-white font-Mulish focus:outline-none  md:px-4  font-normal w-full text-sm md:w-32 h-8 focus:ring-0 focus:ring-blue focus:border-transparent ${
          props.category ? "rounded-md" : ""
        }`}
        onClick={props.click}
      >
        {props.text}
      </button>
    );
  }
  if (props.variant === "danger") {
    return (
      <button
        type={props.type}
        className={`bg-white text-red-500 font-Mulish focus:outline-none px-4 py-2  rounded font-normal
        `}
        onClick={props.loader ? () => {} : props.click}
      >
        {props.text}
      </button>
    );
  }
}

export default Button;
