import { Store } from "pullstate";

export const authStore = new Store({ token: "" });

const getData = () => {
  try {
    const token = localStorage.getItem("auth");
    if (token !== null) {
      let saved = JSON.parse(token);
      authStore.replace(saved);
    }
  } catch (e) {
    console.log(e);
  }
};
getData();

authStore.subscribe(
  (s) => s,
  (token) => {
    localStorage.setItem("auth", JSON.stringify(token));
  }
);
