import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Login from "./pages/Login";
import Sidebar from "./components/Sidebar";
import Routes from "./Routes";
import { authStore } from "./store";

function App() {
  const token = authStore.useState((s) => s.token);
  // console.log(token);

  if (!token) {
    return (
      <>
        <Login />
        <Toaster />
      </>
    );
  } else {
    return (
      <>
        <div className=" min-h-screen w-full lg:flex  ">
          <Router>
            <Sidebar />
            <div className="w-full flex items-center space-x-4 px-4 h-12 lg:hidden"></div>
            <div
              style={{
                maxWidth: "210px",
                minWidth: "210px",
              }}
              className="min-h-full"
            ></div>
            <Routes />
          </Router>
          <Toaster />
        </div>
      </>
    );
  }
}

export default App;
