import ReactModal from "react-modal";
import Success from "../assets/success.gif";

const customStyles = {
  overlay: {
    top: "0%",
    left: "0%",
    backgroundColor: "#1b1b1b80",
    width: "100%",
    height: "100%",
    zIndex: "99999",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "auto",
    width: "20%",
    marginRight: "-50%",
    padding: "0",
    transform: "translate(-50%, -50%)",
  },
};

const SuccessModal = (props) => {
  return (
    <div>
      <ReactModal
        isOpen={props.isOpen}
        style={customStyles}
        // onRequestClose={props.onClose}
        // shouldCloseOnOverlayClick={true}
      >
        <div className="flex items-center justify-center min-h-full w-full flex-col">
          <img src={Success} className="h-28 w-28" alt="success"></img>
          <p className="text-green-500 font-Mulish text-2xl font-semibold flex-2 pb-2">
            Success
          </p>
          <p className="text-gray-700 font-Mulish text-sm font-normal flex-2  text-center px-2 pb-6">
            {props.text}
          </p>
        </div>
      </ReactModal>
    </div>
  );
};

export default SuccessModal;
