import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import NoData from "../assets/noData.svg";
import Loader from "react-loader-spinner";

const List = ({
  rows,
  columns,
  totalRow,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleView,
  handleEdit,
  tableLoader,
}) => {
  return tableLoader ? (
    <div className="flex items-center justify-center  min-w-full py-20 h-full">
      <Loader type="Oval" color="#FFBD39" height={35} width={50} />
    </div>
  ) : (
    <Box sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <TableContainer className="px-2 md:py-3 md:px-4 ">
        <Table
          stickyHeader
          aria-label="organisation table"
          style={{ borderCollapse: "separate", borderSpacing: "0px 8px" }}
        >
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ position: "relative" }}>
            {rows === undefined || rows?.length < 1 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <div className=" flex items-center min-h-[50vh] justify-center min-w-full flex-col gap-2">
                    <img
                      src={NoData}
                      alt="no data"
                      className="object-contain"
                    />
                    <p>No data found</p>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              rows?.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    className="cursor-pointer hover:bg-gray-50 "
                    onClick={
                      handleEdit ? () => handleEdit(row) : () => handleView(row)
                    }
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalRow !== undefined ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRow}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </Box>
  );
};

export default List;
