import React from "react";

function Cards({ heading, totalCount, period }) {
  return (
    <div
      className={`w-full  pl-4 mt-3 justify-center flex flex-col border border-gray-200 h-24 rounded-lg`}
    >
      <div className="flex py-2">
        <p className="font-light font-Mulish text-gray-500 text-xs">
          {heading}
        </p>
      </div>
      <div className="flex space-x-2 items-center justify-start">
        <p className="font-Mulish font-bold text-gray-900 text-xl">
          {totalCount}
        </p>
        <p className="font-Mulish font-semibold text-label text-xs">{period}</p>
      </div>
    </div>
  );
}

export default Cards;
