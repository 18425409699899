import React from "react";
import { FiTrendingUp, FiDollarSign, FiUsers, FiBox } from "react-icons/fi";

function Stats({ organistionCount, vendorCount, totalRevenue, spCount }) {
  return (
    <div className="flex lg:items-center lg:space-x-12 pr-4 pl-4 lg:pl-8 pt-2 pb-5 lg:pr-20 flex-col lg:flex-row">
      <div className="flex items-center  space-x-6 px-6 py-6 bg-gray-50 w-full rounded-xl mb-3">
        <div className="flex items-center justify-center bg-purple-200 px-2 py-2 rounded-full">
          <FiTrendingUp className="text-2xl text-purple-500" />
        </div>
        <div className="flex flex-col">
          <p className="text-label font-semibold">{organistionCount}</p>
          <p className="text-label font-normal">Organistations</p>
        </div>
      </div>
      <div className="flex items-center space-x-6 px-6 py-6 bg-gray-50 w-full rounded-xl mb-3">
        <div className="flex items-center justify-center bg-red-100 px-2 py-2 rounded-full">
          <FiBox className="text-2xl text-red-500" />
        </div>
        <div className="flex flex-col">
          <p className="text-label font-semibold">{vendorCount}</p>
          <p className="text-label font-normal">Retailers</p>
        </div>
      </div>
      <div className="flex items-center space-x-6 px-6 py-6 bg-gray-50 w-full rounded-xl mb-3">
        <div className="flex items-center justify-center bg-green-200 px-2 py-2 rounded-full">
          <FiUsers className="text-2xl text-green-600" />
        </div>
        <div className="flex flex-col">
          <p className="text-label font-semibold">{spCount}</p>
          <p className="text-label font-normal">Salesperson</p>
        </div>
      </div>
      <div className="flex items-center space-x-6 px-6 py-6 bg-gray-50 w-full rounded-xl mb-3">
        <div className="flex items-center justify-center bg-yellow-100 px-2 py-2 rounded-full">
          <FiDollarSign className="text-2xl text-primaryDarker" />
        </div>
        <div className="flex flex-col">
          <p className="text-label font-semibold">{totalRevenue}</p>
          <p className="text-label font-normal">Revenue</p>
        </div>
      </div>
    </div>
  );
}

export default Stats;
